import React, { useEffect, useState } from "react";
import "./Support.css"; // You can define your custom styles for the Login component
import { Container, Row, Col, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import CustomInput from "./CustomInput";
import instance, { APP_COLORS, APP_IMAGES } from "../common/Api";
// secretKey:6LcT-LgpAAAAAMclxt5GNNLhZz1MY1cAoeKB7JJl
const Support = (props) => {
  const [osType, setOsType] = useState("");

  const btnArray = [
    "Advertising",
    "Application",
    "Billing",
    "Streaming",
    "Partnership",
    "Upcoming",
    "Other",
  ];

  useEffect(() => {
    getOperatingSystem();
  }, []);

  const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown";

    // Regular expressions to identify various operating systems
    const osPatterns = {
      windows: /Windows/i,
      mac: /Macintosh|MacIntel|MacPPC|Mac68K/i,
      ios: /iPhone|iPad|iPod/i,
      android: /Android/i,
      linux: /Linux/i,
    };

    // Check if userAgent matches any of the OS patterns
    if (osPatterns.windows.test(userAgent)) {
      os = "Windows";
    } else if (osPatterns.mac.test(userAgent)) {
      os = "Mac";
    } else if (osPatterns.ios.test(userAgent)) {
      os = "iOS";
    } else if (osPatterns.android.test(userAgent)) {
      os = "Android";
    } else if (osPatterns.linux.test(userAgent)) {
      os = "Linux";
    }
    setOsType(os);
    return os;
  };

  const [selectedButton, setSelectedButton] = useState(null);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [Loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const PosrQuery = () => {
    if (
      query !== "" &&
      userEmail !== "" &&
      userName !== "" &&
      userPhone !== ""
    ) {
      const payload = {
        app_version: "1.1.0",
        enquiry: "25",
        os: osType,
        platform: "Chrome",
        type: queryType,
        user_email: userEmail,
        app_version: "",
        user_name: userName,
        user_phone: userPhone,
        version_code: "123",
      };
      // console.log(payload);
      instance
        .post(`/create_enquiry?ip=${props.ip}`, payload)
        .then((response) => {
          let res = response;
          if (res.status) {
            alert("Your Enquiry is registered");
            window.location.href = "/";
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      alert("All fields are required!");
    }
  };
  const handleButtonClick = (buttonName) => {
    setQueryType(buttonName);
    if (selectedButton === buttonName) {
      setSelectedButton(null);
    } else {
      setSelectedButton(buttonName);
    }
    // console.log("selectedButton>", selectedButton);
  };

  function isMobileDevice() {
    return window.matchMedia("(max-width: 768px)").matches;
  }

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={
        isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              position: "relative",
              backgroundImage: isMobile
                ? `url(${APP_IMAGES.APP_MAIN_BG_MOB})`
                : `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
              backgroundSize: "cover",
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "round",
              backgroundBlendMode: "overlay",
            }
          : {
              width: "100%",
              minHeight: "55rem",
              position: "relative",
              backgroundImage: isMobile
                ? `url(${APP_IMAGES.APP_MAIN_BG_MOB})`
                : `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
              backgroundSize: "cover",
              backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "round",
              backgroundBlendMode: "overlay",
            }
      }
    >
      <div className="centered-container">
        <Container
          className="custom-container"
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            backgroundColor: APP_COLORS.APP_NAV_COLOR,
            // backgroundImage: `url(${APP_IMAGES.POP_UP_BG})`,
            // backgroundSize: "cover",
          }}
        >
          <Row>
            <h4 style={{ color: APP_COLORS.APP_TEXT, marginBottom: "30px" }}>
              Do you have some solid feedback for us?
            </h4>
          </Row>
          <Row>
            {isMobile ? (
              <>
                <Col
                  xs={12}
                  className="custom-col border-md-end order-1 order-md-2 no-border mt-2 mt-md-0"
                >
                  {btnArray.map((name, idx) => (
                    <Button
                      key={idx}
                      className="button-list-mobile"
                      size="sm"
                      style={{
                        backgroundColor:
                          selectedButton === name ? APP_COLORS.APP_DARK_1 : APP_COLORS.APP_DARK_2,
                          color: APP_COLORS.APP_BLACK
                      }}
                      onClick={() => handleButtonClick(name)}
                    >
                      {name}
                    </Button>
                  ))}
                </Col>
                <Col xs={12} className="order-1 order-md-1">
                  <CustomInput
                    label="Full Name"
                    placeholder=""
                    error="This field is required"
                    type={"text"}
                    onValueChange={(value) => setUserName(value)}
                  />
                  <CustomInput
                    label="Email"
                    placeholder=""
                    error="This field is required"
                    type={"email"}
                    onValueChange={(value) => setUserEmail(value)}
                  />
                  <CustomInput
                    label="Mobile Number"
                    placeholder=""
                    error="This field is required"
                    type={"number"}
                    onValueChange={(value) => setUserPhone(value)}
                  />
                  <CustomInput
                    label="Tell us more about your issue"
                    placeholder=""
                    error="This field is required"
                    as={"textarea"}
                    onValueChange={(value) => setQuery(value)}
                  />
                  {/* <div style={{ textAlign: "center", marginBottom: "-4rem" }}>
                    <ReCAPTCHA
                      sitekey="6LcT-LgpAAAAAHLdmvu7Gwh_wmBEa6V9hbIxzSG6"
                      size={isMobile ? "compact" : "normal"}
                      onChange={() => console.log("hello")}
                    />
                  </div> */}
                  <Button
                    style={{
                      marginTop: "10px",
                      backgroundColor: APP_COLORS.APP_DARK_2,
                      borderWidth: "0",
                      color: APP_COLORS.APP_WHITE
                    }}
                    onClick={() => PosrQuery()}
                  >
                    Send Message
                  </Button>
                </Col>
              </>
            ) : (
              <>
                <Col
                  xs={12}
                  md={6}
                  className="custom-col border-md-end order-md-1 order-2 no-border mt-2 mt-md-0"
                >
                  {btnArray.map((name, idx) => (
                    <Button
                      key={idx}
                      className="button-list"
                      size="lg"
                      style={{
                        backgroundColor:
                          selectedButton === name
                            ? APP_COLORS.APP_TEXT
                            : "#9a9a9a",
                      }}
                      onClick={() => handleButtonClick(name)}
                    >
                      {name}
                    </Button>
                  ))}
                </Col>
                <Col xs={12} md={6} className="order-md-2 order-1">
                  <CustomInput
                    label="Full Name"
                    placeholder=""
                    error="This field is required"
                    type={"text"}
                    onValueChange={(value) => setUserName(value)}
                  />
                  <CustomInput
                    label="Email"
                    placeholder=""
                    error="This field is required"
                    type={"email"}
                    onValueChange={(value) => setUserEmail(value)}
                  />
                  <CustomInput
                    label="Mobile Number"
                    placeholder=""
                    error="This field is required"
                    type={"number"}
                    onValueChange={(value) => setUserPhone(value)}
                  />
                  <CustomInput
                    label="Tell us more about your issue"
                    placeholder=""
                    error="This field is required"
                    as={"textarea"}
                    onValueChange={(value) => setQuery(value)}
                  />
                  {/* <ReCAPTCHA
                    sitekey="6LcT-LgpAAAAAHLdmvu7Gwh_wmBEa6V9hbIxzSG6"
                    onChange={() => console.log("hello")}
                  /> */}
                  <Button
                    style={{
                      marginTop: "10px",
                      backgroundColor: APP_COLORS.APP_DARK_2,
                      borderWidth: "0",
                      color: APP_COLORS.APP_APP_WHITE
                    }}
                    onClick={() => PosrQuery()}
                  >
                    Send Message
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Support;
