import { APP_COLORS, APP_IMAGES } from "../common/Api";
import "./Cookies.css";
import React from "react";

const CookiePolicy = () => {
  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundImage: `url(${APP_IMAGES.APP_MAIN_BG_WEB})`,
        backgroundSize: "cover",

        backgroundRepeat: "round",
        backgroundBlendMode: "overlay",
      }}
    >
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            <div className="col-md-12 privacyContentBox">
              <h1
                style={{ color: APP_COLORS.APP_TEXT, paddingBottom: "10px" }}
              >
                Cookie's Policy
              </h1>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                ‘Niovi Digital Ltd.’ is the owner & operator of
                https://www.keeflix.com (referred to as “Website”). The Purpose
                of this document is for you to read and duly understand the
                terms of this Policy (“Cookies’ Policy” or “Policy”).
                <br />
                This Website uses cookies and other similar tracking
                technologies (“Cookies”), which collect information about your
                browsing activities.
                <br />
                Cookies or similar tracking technologies help us to provide you
                best possible website browsing experience and also to improve
                the Website and our services.
                <br />
                <br />
                By browsing the Website, it shall be deemed that you have
                thoroughly read, understood; and acknowledge, agree and consent
                to the terms of this Cookies’ Policy.
                <br />
                Further, by doing so, you also confirm and consent for the
                Website’s usage of cookies and other such tracking technologies.
                <br />
                <br />
                If you don’t wish to accept cookies from the Website, you can
                configure your web browser so that it does not accept cookies;
                however, <br />
                by doing so certain features of the Website may be inaccessible
                to you.
              </p>

              <h6
                style={{ color: APP_COLORS.APP_TEXT, paddingBottom: "10px" }}
              >
                This Cookies’ Policy explains:
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                How we use cookies or other similar tracking software.
                <br />
                Your choices as to whether we can put cookies on your computer
                or mobile, tablet and other similar handheld device (“Computer
                or device”).
                <br />
                The term “Personal Data” as used in this Policy mean the data
                which identifies you as an individual and may include your name
                or online identifiers such as your Browser type and IP Address.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                1. What are Cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                A “cookie” is a small text file. When you visit the website or
                use an application, the website/application sends cookies to
                your computer or device. Your computer or device stores cookies
                in a file located inside your web borrower.
                <br />
                Different types of cookies keep track of different activities.
                The same have been explained in Clause 4 hereinbelow.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                2. How we use cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                The purpose of the cookies is to help the Website keep track of
                your visits and activities. These cookies allow us to recognise
                users, tailor the contents of our Website to fit the needs of
                Website Visitors, and improve user’s experience. These cookies
                allow us to keep a record of your most recent visit or to record
                your log in information; to obtain information about your visits
                and about the device you use to access the Website; your IP
                address, pseudonymous identifiers, operating system, browser
                type, and depending on the cookie, also include the reporting of
                statistical data about our users’ browsing actions and patterns.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                3. Why we use cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                We deploy cookies to:
              </p>
              <p style={{ paddingLeft: "40px", color: APP_COLORS.APP_WHITE }}>
                1. Provide online services more suited to computer or device you
                connect; and /or
                <br />
                2. To prevent and detect fraud and keep your connection secure;
                and /or
                <br />
                3. When you visit our Website from any computer or device, we
                collect information about your use of Website, such as
                information about the device or browser you use to access the
                Website (including device type, operating system, screen
                resolution etc.), the way you interact with this Website, and
                the IP Address your computer or device connect from. You may not
                be able to initiate or complete some activities with our online
                services unless cookies or similar technologies are installed.
                <br />
                4. We also use cookies to record your most recent visit or to
                record your log in information, which is useful for you to know
                that why you have visited or downloaded in past. We also use
                cookies (and other similar technologies) to:
                <p style={{ paddingLeft: "40px", color: APP_COLORS.APP_WHITE }}>
                  1. Provide products and services that you request and to
                  provide a secure online environment; and /or
                  <br />
                  2. Manage our marketing relationship; and /or
                  <br />
                  3. Give you a better online experience and track website
                  performance; and /or
                  <br />
                  4. Provide products and services that you request and to
                  provide a secure online environment; and /or
                  <br />
                </p>
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                4. Cookies Categories
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                This clause explains each cookie categories in more detail. The
                length of time a cookie stays on your computer or device depends
                on its type. We use following types of cookies on our Website{" "}
                <br />
                Session cookies:
                <br />
                These are the temporary cookies which only exist, during the
                time you use the Website (or more strictly, until you close the
                relevant browser). Session cookies help our website remember
                what your chose in the previous page, avoiding the need to
                re-enter information and improve your experience whilst using
                the Website.
                <br />
                Persistent cookies:
                <br />
                These are the cookies which stay on your computer or device
                after you have closed your browser and remain in your browser’s
                sub-folders until they expire or you delete them. These cookies
                can further be sub-divided into following categories:
                <br />
                Authentication cookies:
                <br />
                These cookies enable a user to log into our website and
                recognize such user when navigating website. These cookies are
                the essential cookies and used to keep the Website secure.
                <br />
                Functionality cookies:
                <br />
                These cookies remember your preferences and tailor the Website
                to provide enhanced feature or personalised online experience.
                These cookies enable (a) log in faster by recognising you if you
                are logged into the Website, and (b) remember the relevant
                information as you browse from page to page to save you
                re-entering the same information repeatedly.
                <br />
                Security cookies:
                <br />
                These cookies maintain online security and protect against
                malicious activities and /or violation of our User Agreement.
                <br />
                Analytics cookies:
                <br />
                These cookies are used to understand and analyse how you use and
                explore the Website and look for ways to improve it. These can
                also be used to test new advertisements, pages, or features to
                gauge user/s reaction. These Analytics cookies may be our own or
                third party cookies and include Google Analytics.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                5. Third Parties cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                Third Parties may provide certain contents or application
                including advertisements on our website. To provide such
                services or functionalities these Third Parties may use cookies
                (alone or in conjunction with web beacons or other tracking
                technologies) to collect information about you and about your
                use of the Website, on which we have no control. Unless
                expressly stated otherwise or elsewhere, We do not provide any
                personal data to these Third Parties, however, they may collect
                information, including personal data such as IP Address, browser
                type, version, time zone setting, and location, operating system
                and platform and other technology on the devices you use to
                access the Website. They may use those information to provide
                you internet-based advertising or other targeted content. We
                strongly advise and recommend you to check Privacy and Cookies
                Policies of those Third Party Websites for information about the
                cookies they may use and collection of the personal data. We
                don’t accept any responsibility for any content contained in
                these Third Party Websites.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                6. You may please note
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                You have option to delete or turning off one of more types of
                cookies. However same will not delete any data that have been
                downloaded /collected in past, and we may still use the data
                collected up to that point of action of deleting or turning off.
                However upon such deleting or turning off we will stop
                collecting new data.
                <br />
                You can turn off or delete cookies in your browser. If you do
                this, it may affect Website or our certain services and also
                affect Websites that use similar cookies. Some of our cookies
                collect data for more than one use. We will use these cookies
                only for essential purposes, unless you have given your consent
                to any other use they have.
              </p>

              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                {" "}
                7. Controlling and deleting cookies
              </h6>
              <p style={{ color: APP_COLORS.APP_WHITE }}>
                On you first visit or visits you may see a pop-up to inform you
                that cookies are being used. Although this pop-up may not
                usually appear on subsequent visits. You may withdraw your
                cookie consent at any time by changing your browser or device
                setting.
                <br />
                You can set up your browser / device setting to delete or refuse
                some or all the cookies, or to be notified when you are sent a
                cookies and therefore, choose whether or not to accept it. You
                may delete or refuse, some or all of the cookies, on our Website
                any time.
                <br />
                You may also opt out of Third Parties cookies by following the
                instructions provided by each Third Party in its Privacy Policy.
                <br />
                Unless you have adjusted your browser setting so that they
                delete or refuse cookies, cookies will be issued or reissued
                when you direct your browser to our Website. If you do delete or
                refuse cookies, but subsequently decide to allow them, you
                should adjust your browser setting and continue using our
                Website.
                <br />
                If you do delete some or all your cookies, some functionality on
                our Website may be disables and as result you may not be able to
                access certain part or feature of our Website and your browsing
                experience of our Website may be affected.
                <br />
                To find out more about cookies (including that of managing and
                deleting them) you may visit www.allaboutcookies.org.
                Alternatively you may search internet for other independent
                information on cookies.
                <br />
              </p>
              <h6 style={{ color: APP_COLORS.APP_TEXT }}>
                8. For knowing other use of Personal data you may visit our
                Privacy policy.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
